import React from "react"


import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectCard from "../components/project-card"
import GoSun from "../images/projects/gosun/cover--2.jpg"
import Indiegogo from "../images/projects/indiegogo/cover.jpg"
import Kickstarter from "../images/projects/kickstarter/cover_kickstarter.jpg"
import StartEngine from "../images/projects/startengine/cover_startengine.jpg"
import Auris from "../images/projects/auris/cover.jpg"
import Labgrown from "../images/projects/labgrown/cover.jpg"
import LatimerApp from "../images/projects/latimer-app/cover.jpg"
import GrownAs from "../images/projects/grownas/cover.jpg"
import Onyx from "../images/projects/onyx-design/cover.jpg"
import Versed from "../images/projects/versed/cover.jpg"
import JackRabbit from "../images/projects/jack-rabbit/cover.jpg"
import IsaLazo from "../images/projects/isa-lazo/cover.jpg"
import RelevantSkin from "../images/projects/relevant-skin/cover.jpg"
import VirginiaSin from "../images/projects/sin/cover.jpg"
import KindBody from "../images/projects/kindbody/cover.jpg"
// import AboutDesktop from "../../content/images/projects/labgrown/about--desktop.mp4"

class workPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Work" />

          <div class="adv-gallery-v3 mb-20">
            <ul class="columns-1 lg:columns-2">

              <ProjectCard classes="" title="Versed Skin" url="versed-skin"  description="Revolutionizing the natural skincare industry with all-natural, vegan ingredients for cleansers, moisturizers, serums, masks." cover={Versed} role="Development" />

              <ProjectCard classes="" title="Relevant Skin" url="relevant-skin"  description="Science meets soul with a heaping dose of good vibes." cover={RelevantSkin} role="Development" />

              <ProjectCard classes="" title="SIN" url="virginia-sin"  description="Building a minimalist site for the Brooklyn-based, handmade ceramics and home goods brand." cover={VirginiaSin} role="Design + Development"/>

              <ProjectCard classes="" title="Kindbody" url="kindbody"  description="Creating an inclusive space for direct at-home fertility care." cover={KindBody} role="Design + Development"/>

              <ProjectCard classes="" title="JackRabbit" url="jackrabbit"  description="Making commuting fun again." cover={JackRabbit}  role="Development"/>

              <ProjectCard classes="" title="Isa Lazo" url="isa-lazo"  description="Clean skincare crafted from the purest elements of nature." cover={IsaLazo}  role="Development"/>
              
              <ProjectCard classes="" title="GrownAs Mac" url="grownas-mac"  description="Motion-focused e-commerce experience for the cheesiest vegan mac available. Developed on Shopify in a week." cover={GrownAs}  role="Design + Development"/>

              <ProjectCard classes="" title="Labgrown" url="labgrown"  description="Creating an e-commerce experience to position labgrown as a pioneer in the sustainable jewelry market." cover={Labgrown}  role="Design + Development + Branding"/>

              <ProjectCard classes="" title="Auris" url="auris"  description="The sound of the past, built for the future." cover={Auris}  role="Design + Development + Branding"/>

              <ProjectCard classes="" title="latimer.app" url="latimer-app"  description="An experiment to build the fastest e-commerce site possible using Gatsby.js + Shopify." cover={LatimerApp}  role="Design + Development + Branding"/>

              <ProjectCard classes="" title="ONYX Design" url="onyx-design"  description="Rebrand + portfolio redesign for a web development agency based in Los Angeles." cover={Onyx}  role="Design + Development + Branding"/>
              
              <ProjectCard classes="" title="GoSun" url="gosun" description="Creating a winning brand, design system, and e-commerce experience to disrupt the outdoors market. Raised $2m+ via crowdfunding and secured Series A funding in first 8 months." cover={GoSun}  role="Design + Development + Branding"/>


              </ul>

          </div>

      </Layout>
    )
  }
}

export default workPage